import { Model } from "pinia-orm";
import Place from "@/models/Place";
import TripPlace from "@/models/TripPlace";
import TripUser from "@/models/TripUser";
import User from "@/models/User";

export default class Trip extends Model {
  static entity = "trips";
  static fields() {
    return {
      id: this.string(null),
      name: this.string(""),
      description: this.string(""),
      arrivalDatetime: this.attr(null),
      shareKey: this.string(null),
      placeType: this.string(null),
      chosenPlaceId: this.attr(null),
      chosenPlace: this.belongsTo(Place, "chosenPlaceId"),
      users: this.belongsToMany(User, TripUser, "tripId", "userId"),
      places: this.belongsToMany(Place, TripPlace, "tripId", "placeId"),
      cuisines: this.attr([]),
      priceRanges: this.attr([]),
      vehicleModes: this.attr([]),
      insideParis: this.boolean(false),
    };
  }
}
