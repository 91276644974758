<template>
  <v-dialog fullscreen transition="dialog-bottom-transition" :value="true">
    <v-sheet class="d-flex fill-height flex-column">
      <v-toolbar class="flex-grow-0" flat>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="flex-grow-1 overflow-y-auto">
        <place-type-toggle v-model="placeType" />
        <template v-if="placeType === 'stop'">
          <div class="text-h6 mb-3">{{ $t("placeFilters.vehicleModes") }}</div>
          <vehicle-mode-select v-model="vehicleModes" class="mb-5" />
        </template>
        <template v-else>
          <div class="text-h6 mb-3">{{ $t("placeFilters.price") }}</div>
          <price-range-select v-model="priceRanges" class="mb-5" />
          <div class="text-h6">{{ $t("placeFilters.cuisines") }}</div>
          <cuisine-select v-model="cuisines" class="mb-5" />
        </template>
        <v-checkbox
          v-model="insideParis"
          :label="$t('placeFilters.insideParis')"
        />
      </v-container>
      <v-footer color="white">
        <v-container>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            rounded
            @click="onSave"
          >
            {{ $t("placeFilters.action") }}
          </v-btn>
        </v-container>
      </v-footer>
    </v-sheet>
  </v-dialog>
</template>

<script>
import CuisineSelect from "@/components/inputs/CuisineSelect.vue";
import PlaceTypeToggle from "@/components/inputs/PlaceTypeToggle.vue";
import PriceRangeSelect from "@/components/inputs/PriceRangeSelect.vue";
import VehicleModeSelect from "@/components/inputs/VehicleModeSelect.vue";

export default {
  components: {
    CuisineSelect,
    PlaceTypeToggle,
    PriceRangeSelect,
    VehicleModeSelect,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    const tripId = this.$route.params.tripId;
    const trip = this.$tripRepo.find(tripId);
    return {
      cuisines: trip.cuisines,
      insideParis: trip.insideParis,
      placeType: trip.placeType,
      priceRanges: trip.priceRanges,
      trip,
      tripId,
      vehicleModes: trip.vehicleModes,
    };
  },
  methods: {
    close() {
      const routeName = this.prevRoute?.name || "tripPlaces";
      this.$router.push({
        name: routeName,
        params: { tripId: this.tripId, placeId: this.placeId },
      });
    },
    onSave() {
      const trip = this.$tripRepo.find(this.tripId);
      trip.cuisines = this.cuisines;
      trip.insideParis = this.insideParis;
      trip.priceRanges = this.priceRanges;
      trip.placeType = this.placeType;
      trip.vehicleModes = this.vehicleModes;
      this.$tripRepo.save(trip);
      this.$placeRepo.search(
        this.tripId,
        this.placeType,
        trip.cuisines,
        trip.priceRanges,
        trip.vehicleModes,
        trip.insideParis
      );
      this.close();
    },
  },
};
</script>
