<template>
  <div>
    <v-chip v-for="mode in modes" :key="mode" color="secondary">
      <v-icon v-if="mode === 'BUS'">mdi-bus</v-icon>
      <v-icon v-if="mode === 'RAIL'">mdi-train</v-icon>
      <v-icon v-if="mode === 'SUBWAY'">mdi-subway-variant</v-icon>
      <v-icon v-if="mode === 'TRAM'">mdi-tram</v-icon>
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    modes: {
      type: Array,
      required: true,
    },
  },
};
</script>
