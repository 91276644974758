<template>
  <div class="mapbox-map">
    <div id="map"></div>
    <slot v-if="loaded"></slot>
  </div>
</template>

<script>
import { computed } from "vue";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;

export default {
  provide() {
    return {
      map: computed(() => this.map),
    };
  },
  props: {
    center: {
      type: Array,
      default: () => [0, 0],
    },
    bounds: {
      type: Array,
      default: undefined,
    },
    disableRotation: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: "en",
    },
    minZoom: {
      type: Number,
      default: 0,
    },
    maxZoom: {
      type: Number,
      default: 22,
    },
    mapStyle: {
      type: String,
      required: true,
    },
    zoom: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loaded: false,
  }),
  mounted() {
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.mapStyle,
      keyboard: false,
      center: this.center,
      bounds: this.bounds,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      zoom: this.zoom,
    });
    this.map.on("load", () => {
      this.loaded = true;
      this.$emit("load");
      if (this.disableRotation) {
        this.map.dragRotate.disable();
        this.map.touchZoomRotate.disableRotation();
      }
      this.map.on("click", (event) => this.$emit("click", event));
      this.map.on("movestart", (event) => this.$emit("movestart", event));
      this.map.on("moveend", (event) => this.$emit("moveend", event));
      this.map.getStyle().layers.forEach((layer) => {
        if (layer.id.endsWith("-label")) {
          this.map.setLayoutProperty(layer.id, "text-field", [
            "get",
            `name_${this.language}`,
          ]);
        }
      });
    });
  },
  destroyed() {
    this.map.remove();
    this.map = null;
  },
};
</script>

<style lang="sass">
.mapboxgl-map
  position: absolute
  top: 0
  bottom: 0
  width: 100%

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right
  display: none
</style>
